<template>
  <v-app id="contracts-list" class="contracts-list">
    <!--begin::Dashboard-->
    <div class="card card-custom card-stretch h-auto">
      <!--begin::Header-->
      <div class="card-header border-0 py-5 align-items-center">
        <h3 class="card-title align-items-start flex-column m-0 py-3">
          <span class="card-label font-weight-bolder text-dark">
            Contracts List
          </span>
        </h3>
        <button
          class="btn btn-light font-weight-bolder text-dark-75"
          v-b-toggle.filter-box
        >
          <v-icon>mdi-tune</v-icon> Filter
        </button>
      </div>
      <!--end::Header-->
      <!--begin::Body-->

      <ContractsListFilters @applyFilter="applyFilter" />
      <div class="card-body pt-0 pb-3">
        <div class="tab-content">
          <!--begin::Table-->
          <div
            v-if="contracts && contracts.length"
            class="table-responsive mb-0"
          >
            <table
              class="table table-head-custom table-vertical-center table-striped text-left table-head-bg table-borderless"
            >
              <thead>
                <tr>
                  <th style="min-width: 180px">
                    <span>Contract Name</span>
                  </th>
                  <th style="min-width: 130px" class="text-center">
                    <span>Official Squad Name</span>
                  </th>
                  <th style="min-width: 130px" class="text-center">
                    <span>Internal squad name</span>
                  </th>
                  <th style="min-width: 180px" class="text-center">
                    <span>Talent Name</span>
                  </th>
                  <th style="min-width: 130px">
                    <span>Contract start Date</span>
                  </th>
                  <th style="min-width: 130px">
                    <span>Contract end Date</span>
                  </th>
                  <th style="min-width: 100px">
                    <span>Renewal state</span>
                  </th>
                  <th style="min-width: 130px">
                    <span>Contract status</span>
                  </th>
                  <th style="min-width: 180px" class="text-center">
                    <span>Account manager</span>
                  </th>
                  <th style="min-width: 100px">
                    <span>Salary</span>
                  </th>
                  <th style="min-width: 100px">
                    <span>Margin</span>
                  </th>
                  <th style="min-width: 100px">
                    <span>WHT</span>
                  </th>
                  <th style="min-width: 100px">
                    <span>Price</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="contract in contracts"
                  :key="contract.id"
                  @click="
                    openLinkInNewTab(`/dashboard/contract/${contract.id}`)
                  "
                >
                  <td class="font-weight-bolder">
                    <span>
                      {{ contract.name | trimmedName(contract.name) }}
                    </span>
                  </td>
                  <!--   <td style="max-width: 185px">
                    <div>
                      <div class="text-dark-75 font-weight-bolder text-clamp-2">
                        {{
                          contract.client_name
                            | trimmedName(contract.client_name)
                        }}
                      </div>
                      <div>
                        <span
                          class="text-muted font-size-sm"
                          style="word-break: break-all"
                        >
                          {{ contract.client_email }}
                        </span>
                      </div>
                    </div>
                  </td> -->
                  <td class="font-weight-bolder text-center">
                    <span v-if="contract.project_name">
                      {{
                        contract.project_name
                          | trimmedName(contract.project_name)
                      }}
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td class="font-weight-bolder text-center">
                    <span v-if="contract.project_internal_name">
                      {{
                        contract.project_internal_name
                          | trimmedName(contract.project_internal_name)
                      }}
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td class="font-weight-bolder text-center">
                    <span v-if="contract.talent.full_name">
                      {{ contract.talent.full_name }}
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td class="font-weight-bolder">
                    <span>
                      {{ contract.start_date }}
                    </span>
                  </td>
                  <td class="font-weight-bolder">
                    <span>
                      {{ contract.end_date }}
                    </span>
                  </td>
                  <!--  <td class="font-weight-bolder">
                    <span>
                      {{ contract.renewal_type }}
                    </span>
                  </td> -->
                  <td class="font-weight-bolder">
                    <span>
                      {{ renewalStates[contract.renewal_state] || "-" }}
                    </span>
                  </td>
                  <td class="font-weight-bolder">
                    <span>
                      {{ contractStatuses[contract.status] }}
                    </span>
                  </td>

                  <td style="max-width: 185px" class="text-center">
                    <div v-if="contract.account_manager">
                      <div class="text-dark-75 font-weight-bolder text-clamp-2">
                        {{ contract.account_manager.name }}
                      </div>
                    </div>
                    <div v-else>-</div>
                  </td>

                  <td class="font-weight-bolder">
                    <span style="word-break: break-all">
                      {{ contract.salary.basic_salary }}
                    </span>
                  </td>
                  <td class="font-weight-bolder">
                    <span>
                      {{ contract.salary.margin }}
                    </span>
                  </td>
                  <td class="font-weight-bolder">
                    <span>
                      {{ contract.salary.wht_value }}
                    </span>
                  </td>
                  <td class="font-weight-bolder">
                    <span style="word-break: break-all">
                      {{ contract.salary.total_salary }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h5
            class="my-5 text-muted text-center"
            v-else-if="contracts && contracts.length === 0"
          >
            No Results Found
          </h5>
          <div class="pagination-container">
            <p
              v-if="contracts && contracts.length > 0"
              class="pagination-container__desc"
            >
              {{
                $t("showing_results", {
                  length: contracts.length,
                  total: contractsMeta.total,
                })
              }}
            </p>
            <b-pagination
              v-if="contractsMeta && contractsMeta.last_page > 1"
              v-model="page"
              :total-rows="contractsMeta.total"
              :per-page="perPage"
              align="center"
              last-number
              first-number
            >
            </b-pagination>
          </div>

          <!--end::Table-->
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Dashboard-->
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ContractsListFilters from "@/components/contracts-list/ContractsListFilters.vue";
export default {
  name: "ContractsList",
  components: { ContractsListFilters },
  data() {
    return {
      page: 1,
      perPage: 8,
      activeQualifiedId: null,
      activeContactedId: null,
      query: {},
      renewalStates: {
        client_pending: "Pending client action",
        talent_pending: "Pending talent action",
        operation_pending: "Pending operations",
        client_rejected: "Rejected by client",
        talent_rejected: "Rejected by talent",
        renewed: "Renewed",
      },
      contractStatuses: {
        future: "Future",
        active: "Active",
        deleted: "Deleted",
        ended: "Ended",
      },
    };
  },
  filters: {
    trimmedName(name) {
      return name.length > 20 ? name.slice(0, 20) + "..." : name;
    },
  },
  computed: {
    ...mapGetters({
      contracts: "contracts/getContractsData",
      contractsMeta: "contracts/getContractsMeta",
    }),
    contractsQuery() {
      return {
        page: this.page,
        perPage: this.perPage,
        ...this.query,
      };
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Contracts" },
    ]);
    this.handleGettingContractsData();
  },
  watch: {
    page() {
      this.handleGettingContractsData();
    },
  },
  methods: {
    ...mapActions({
      getContractsData: "contracts/getContractsData",
      deleteSelectedClients: "clients/deleteSelectedClients",
    }),
    async handleGettingContractsData() {
      await this.getContractsData(this.contractsQuery);
    },
    openLinkInNewTab(url) {
      window.open(url, "_blank");
    },
    applyFilter(query) {
      this.query = query;
      this.page = 1;
      this.handleGettingContractsData();
    },
  },
};
</script>

<style lang="scss" scoped>
.table.table-vertical-center {
  tr {
    &:hover {
      background: #f8f9fa;
    }
  }
  th {
    color: #3f4254 !important;
  }
  td {
    padding-top: 16px;
    vertical-align: top;
    &:not(:first-of-type, :last-of-type) {
      cursor: pointer;
    }
  }
}
::v-deep {
  .v-input--selection-controls .v-input__slot {
    justify-content: center;
  }
  .v-input--selection-controls {
    margin-top: 0;
  }

  .v-input--selection-controls.v-input--checkbox {
    padding: 0;
  }
}
</style>
